<template>
  <div class="all">
    <div class="header">
      <van-nav-bar>
        <template #right>
          <LanguageView />
        </template>
      </van-nav-bar>
      <div class="header-img-backgroud"
        :style="{ 'background-color': brandConfig?.color || '#ffffff', 'display': 'flex', 'justify-content': 'center' }">
        <van-image class="head-img" referrerpolicy="no-referrer" fit="cover"
          :src="require(`@/assets${brandConfig?.header || '/img/esteelauder_logo_header.png'}`)" />
      </div>
    </div>
    <div class="main">
      <router-view :brandConfig="brandConfig" :country="country" :storeId="storeId" :storeName="storeName"></router-view>
    </div>
    <div class="footer"
      :style="{ 'background-color': brandConfig?.color || '#ffffff', 'display': 'flex', 'justify-content': 'center' }">
      <van-image class="footer-img" align="middle" referrerpolicy="no-referrer" fit="cover"
        :src="require(`@/assets${brandConfig?.footer || '/img/esteelauder_logo_footer.png'}`)" />
    </div>
  </div>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Toast,
  NavBar
} from 'vant';
import { Image as VanImage } from 'vant';
import { GetBrandConfig } from '@/config/config';
import { searchStoreName } from '@/api/eformApi';

export default {
  components: {
    [VanImage.name]: VanImage,
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    LanguageView: () => import('./components/LanguageView'),
  },
  data() {
    return {
      language: '',
      country: '',
      brand: '',
      brandConfig: null,
      storeId: '',
      storeName: '',
    };
  },
  created() {
    this.init()
  },
  watch: {
    '$route': 'init'
  },
  methods: {
    init() {
      if (this.$route.name == 'NotFound') {
        return
      }

      this.language = this.$route.params.lang
      this.country = this.$route.params.countryPath
      this.brand = this.$route.params.brand
      this.storeId = this.$route.query.storeId

      if (this.language && this.country && this.brand) {
        // 获取品牌配置
        this.brandConfig = GetBrandConfig(this.country, this.brand)
        if (!this.brandConfig) {
          console.error(`Can not find brand config, country: ${this.country}, storeId: ${this.brand}`)
          this.$router.push({ name: 'NotFound' })
        }

        // 查询门店名称
        searchStoreName({
          storeId: this.storeId,
          country: this.country
        }).then(res => {
          if (res.success) {
            this.storeName = res.data
          } else {
            console.error(`Can not find storeId, country: ${this.country}, storeId: ${this.storeId}`)
            this.$router.push({ name: 'NotFound' })
          }
        })
      } else {
        console.error(`Wrong route, language: ${this.language}, country: ${this.country}, brand: ${this.brand}, storeId: ${this.storeId}`)
        this.$router.push({ name: 'NotFound' })
      }
    }
  }
};
</script>

<style lang="less">
.SecondTitle {
  overflow-wrap: break-word;
  color: black;
  font-size: 20px;
  white-space: nowrap;
  font-family: Helvetica-Regular;
  font-weight: 400;
  margin-top: 20px
}

.tips {
  display: block;
  overflow-wrap: break-word;
  color: #323233;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 15px;
  font-family: Helvetica-Regular;
}

.SecondTitleRegistration {
  overflow-wrap: break-word;
  color: black;
  font-size: 14px;
  // white-space: nowrap;
  font-family: Helvetica-Regular;
  // font-weight: bold;
  margin-top: 20px;
  // font-style:italic;
}

.tipsRegistration {
  display: block;
  overflow-wrap: break-word;
  color: #323233;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 15px;
  font-family: Helvetica-Regular;
  font-style: italic;
  font-weight: bold;
}

.small-tips {
  display: block;
  overflow-wrap: break-word;
  color: rgba(160, 160, 160, 1);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px
}

.header-img-backgroud {
  min-height: 3em;
  padding-top: 20px;
  // background-color: rgba(0, 0, 0, 1);
  width: 100%;
  text-align: center;

}

.head-img {
  width: 100;
  min-height: 100;
  // transform: (translate(3%,-25%));
  // margin: -3%;
  // float: right;
}

.footer-img {
  height: 100%;
  margin: 15px 30%;
  margin-bottom: 10px;
  display: flex;
}

.footer-font {
  display: block;
  overflow-wrap: break-word;
  color: rgba(198, 198, 198, 1);
  font-size: 14px;
  font-family: Helvetica-Regular;
  white-space: nowrap;
  line-height: 14px;
  text-align: center;
}

.all {
  background-color: white;
}

.main {
  margin: 0 5%;
  min-height: calc(70vh);
}

.header {
  /*height: 106px;*/
  line-height: 100px;
}

.footer {
  background-color: white;
}

.van-dropdown-menu__title::after {
  border-color: transparent transparent #000000 #000000
}

.van-dropdown-menu__bar {
  margin-bottom: 2px;
  height: unset;
  box-shadow: none !important;
}

input.agreeCheckBox {
  transform: scale(1.5);
  margin-top: 33%
}

.agreeStatement {
  margin-left: 10px;
}

.second-tips {
  display: inline-flex;
  font-size: 14px;
  font-family: Helvetica-Regular;
  font-weight: 400;
  margin-top: 20px;
  line-height: 20px;
}
</style>
